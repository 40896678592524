import React from 'react';
import { getDoctorByCrm } from '@psp/common';
import RecoverLogin, { RecoverLoginProps } from '../../components/RecoverLogin';

const dispatchers: Pick<RecoverLoginProps, 'getDoctorByCrm'> = {
  getDoctorByCrm,
};

export default function RecoverLoginComposer(): JSX.Element {
  return <RecoverLogin {...dispatchers} />;
}
