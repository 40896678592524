import React from 'react';
import { t, SortingType, d, formatCPF, PatientMedicationFromPatientCareForList } from '@psp/common';

import { Description } from '@material-ui/icons';
import Table, { TableProps } from '../Table';
import MedicationAdminStatusChip from '../MedicationAdminStatusChip';

export interface SortingConfiguration {
    propertyName: keyof {
        id: string;
        [key: string]: unknown;
    },
    sortType: SortingType,
    compareFunction: TableDataComparable
}

export type TableDataComparable = ((a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => number);

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
    }) => {
  if (a[column] === null && b[column] === null) {
    return 0;
  }
  if (a[column] === null) {
    return -1;
  }
  if (b[column] === null) {
    return 1;
  }
  if (a[column] === b[column]) {
    return 0;
  }
  if ((a[column] as any).toString() > (b[column] as any).toString()) {
    return -1;
  }
  return 1;
};

const columns = [
  {
    key: 'patientName',
    label: t('namePatient'),
    compareFunction: CompareByEquality('patientName'),
  },
  {
    key: 'patientCPF',
    label: t('cpf'),
    compareFunction: CompareByEquality('patientCPF'),
    render: function CpfRender(value: string) {
      return value != null ? formatCPF(value) : '- - -';
    },
  },
  {
    key: 'medication',
    label: t('procedureTypeColumn'),
    compareFunction: CompareByEquality('medication'),
  },
  {
    key: 'scheduledDate',
    label: t('expectedDateColumn'),
    compareFunction: CompareByEquality('scheduledDate'),
    render: function ScheduledDateRender(value: string) {
      return value != null ? value : '- - -';
    },
  },
  {
    key: 'realizedDate',
    label: t('concludedDateColumn'),
    compareFunction: CompareByEquality('realizedDate'),
    render: function ConcludedDateRender(value: string) {
      return value != null ? value : '- - -';
    },
  },
  {
    key: 'status',
    label: t('status'),
    compareFunction: CompareByEquality('status'),
    render: function ProcedureStatusRender(value: any) {
      return value != null ? <MedicationAdminStatusChip status={value} /> : '- - -';
    },
  },
];

export type PatientTableProps = {
  data: PatientMedicationFromPatientCareForList[];
  onSortConfig: SortingConfiguration[];
  handleNavigateToPatientDetails: (item: any) => void;
} & Pick<
  TableProps,
    'canSearch' | 'count' | 'onChangePage' | 'onChangeRows' | 'onSearch' | 'page' | 'rowsPerPage' | 'onSortBy'
>;

export default function MedicationAdminTable({ data,
  onSortConfig,
  handleNavigateToPatientDetails,
  ...rest }: PatientTableProps): JSX.Element {
  const extraActions = [
    {
      icon: <Description />,
      label: '',
      onExecute: (item: any) => handleNavigateToPatientDetails(item),
    },
  ];

  return (
    <Table
      {...rest}
      columns={columns}
      data={data}
      size="small"
      extraActions={extraActions}
      onSortConfig={onSortConfig}
    />
  );
}
