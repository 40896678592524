import React, { useEffect, useCallback, useState } from 'react';
import { Box, Paper, Grid, Tabs, Tab } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  t,
  Procedure,
  GetProcedure,
  GetProcedureAuthorizerInfo,
  UpdateProcedureStatus,
  ProcedureStatus,
  ProcedureType,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import Button from '../../Button';
import ProcedureExamTable from '../../ProcedureExamTable';
import ProcedureInfo from '../../ProcedureInfo';
import TabPanel from '../../TabPanel';
import { useStyles } from './styles';
import { useAuth } from '../../../contexts/auth.context';
import ProcedureSupplyTable from '../../ProcedureSupplyTable';
import ProcedureAidTable from '../../ProcedureAidTable';
import ProcedureTravelTable from '../../ProcedureTravelTable';

export type ProcedureDetailsProps = {
  getProcedure: GetProcedure;
  getProcedureAuthorizerInfo: GetProcedureAuthorizerInfo;
  updateProcedureStatus: UpdateProcedureStatus;
};

type ProcedureDetailsState = {
  procedure?: Procedure;
  selected: Map<string, boolean>;
};

const defaultState: ProcedureDetailsState = {
  procedure: undefined,
  selected: new Map(),
};

export default function ProcedureDetails({
  getProcedure,
  getProcedureAuthorizerInfo,
  updateProcedureStatus,
}: ProcedureDetailsProps): JSX.Element {
  const classes = useStyles();
  const [state, setState] = useState<ProcedureDetailsState>(defaultState);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const { showLoading, hideLoading, isLoading, showBackdrop, hideBackdrop } = useLoading();
  const { isDoctor } = useAuth();

  const handleTabChange = useCallback(
    (_event, newValue: number): void => {
      setTabIndex(newValue);
    },
    [setTabIndex],
  );

  const handleChildSelect = useCallback(
    (value) => {
      const { selected } = state;
      if (selected.has(value.id)) {
        selected.delete(value.id);
      } else {
        selected.set(value.id, true);
      }
      setState({
        ...state,
        selected,
      });
      console.log(selected.keys());
    },
    [state],
  );

  const handleApproveClick = useCallback(() => {
    if (!state.procedure) return;
    showLoading();
    (async () => {
      try {
        if (!state.procedure) return;

        if (state?.procedure?.type === ProcedureType.TRAVEL) {
          state.procedure?.children?.forEach((value) => {
            handleChildSelect(value);
          });
        }

        if (state?.procedure?.type === ProcedureType.TRAVEL) {
          state.procedure?.children?.forEach((value) => {
            handleChildSelect(value);
          });
        }

        const res = await updateProcedureStatus.execute({
          procedureId: state.procedure.id,
          status: ProcedureStatus.APPROVED,
          procedureIds: Array.from(state.selected.keys()),
        });
        setState({
          ...defaultState,
          procedure: res,
        });
        toast.success(t('msg.successToApproveProcedure'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } catch (err) {
        if (err.message === 'insufficientInventory') {
          toast.info(t('err.insufficientInventory'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } else {
          toast.info(t('err.failedToApproveProcedure'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }
      } finally {
        hideLoading();
      }
    })();
  }, [state.procedure]);

  const handleRejectClick = useCallback(() => {
    if (!state.procedure) return;
    showLoading();
    (async () => {
      try {
        if (!state.procedure) return;

        if (state?.procedure?.type === ProcedureType.TRAVEL) {
          state.procedure?.children?.forEach((value) => {
            handleChildSelect(value);
          });
        }

        if (state?.procedure?.type === ProcedureType.TRAVEL) {
          state.procedure?.children?.forEach((value) => {
            handleChildSelect(value);
          });
        }

        const res = await updateProcedureStatus.execute({
          procedureId: state.procedure.id,
          status: ProcedureStatus.REJECTED,
        });
        setState({
          ...defaultState,
          procedure: res,
        });
        toast.success(t('msg.successToRejectProcedure'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } catch (err) {
        toast.info(t('err.failedToRejectProcedure'), {
          onOpen: showBackdrop,
          onClose: hideBackdrop,
        });
      } finally {
        hideLoading();
      }
    })();
  }, [state.procedure]);

  useEffect(
    useCallback(() => {
      showLoading();
      (async () => {
        try {
          const res = await getProcedure.execute({ procedureId: id });
          const authorizerInfoRes = await getProcedureAuthorizerInfo.execute({ procedureId: id });
          console.log(authorizerInfoRes, 'authorizerInfoRes');
          res.children = res.children?.map((c: Procedure) => ({
            ...c,
            authorizerInfo: authorizerInfoRes.find((ai) => ai.voucher === c.code),
          }));

          setState({
            ...defaultState,
            procedure: res,
            selected: new Map(),
          });
        } catch (err) {
          console.log(err);
          goBack();
        } finally {
          hideLoading();
        }
      })();
    }, [state]),
    [id],
  );

  if (!state.procedure) {
    return <></>;
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProcedureInfo procedure={state.procedure} />
        </Grid>
        {state.procedure?.type === ProcedureType.CLINICAL_EXAMINATION && (
          <Grid item xs={12}>
            <Paper>
              <Tabs value={tabIndex} onChange={handleTabChange} aria-label="exam info tabs">
                <Tab label={t('exams')} id="exam-tab-0" aria-controls="exam-tabpanel-0" />
                <Tab label={t('supplies')} id="exam-tab-1" aria-controls="exam-tabpanel-1" />
                <Tab label={t('aids')} id="exam-tab-2" aria-controls="exam-tabpanel-2" />
              </Tabs>
              <TabPanel name="exam-tabpanel-0" value={tabIndex} index={0}>
                <ProcedureExamTable
                  canSelect={!isDoctor && state.procedure.status === ProcedureStatus.OPEN}
                  onSelect={handleChildSelect}
                  data={
                    state.procedure.children?.filter((c) => c.type !== ProcedureType.SUPPLY
                      && c.type !== ProcedureType.AID) || []
                  }
                  selectedIds={Array.from(state.selected.keys())}
                />
              </TabPanel>
              <TabPanel name="exam-tabpanel-1" value={tabIndex} index={1}>
                <ProcedureSupplyTable
                  data={
                    state.procedure.children?.filter((c) => c.type === ProcedureType.SUPPLY) || []
                  }
                />
              </TabPanel>
              <TabPanel name="exam-tabpanel-2" value={tabIndex} index={2}>
                <ProcedureAidTable
                  data={
                    state.procedure.children?.filter((c) => c.type === ProcedureType.AID) || []
                  }
                />
              </TabPanel>
            </Paper>
          </Grid>
        )}
        {state.procedure?.type === ProcedureType.TRAVEL && (
          <Grid item xs={12}>
            <Paper>
              <Tabs value={tabIndex} onChange={handleTabChange} aria-label="travel info tabs">
                <Tab label={t('aids')} id="travel-tab-0" aria-controls="travel-tabpanel-0" />
              </Tabs>
              <TabPanel name="travel-tabpanel-0" value={tabIndex} index={0}>
                <ProcedureTravelTable
                  data={
                    state.procedure.children?.filter((c) => c.type === ProcedureType.TRAVEL) || []
                  }
                  selectedIds={Array.from(state.selected.keys())}
                />
              </TabPanel>
            </Paper>
          </Grid>
        )}
        {!isDoctor && state.procedure?.status === ProcedureStatus.OPEN && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="primary" variant="contained" onClick={handleApproveClick}>
                  {t('approveSelected', { count: state.selected.size })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="default"
                  variant="contained"
                  onClick={handleRejectClick}
                  disabled={state.selected.size > 0}
                >
                  {t('reject')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
