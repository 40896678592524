"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionType = void 0;
var PermissionType;
(function (PermissionType) {
    PermissionType["FIRST_MEDICINE"] = "FIRST_MEDICINE";
    PermissionType["CLINICAL_EXAMINATION"] = "CLINICAL_EXAMINATION";
    PermissionType["PATIENT_PROCEDURE"] = "PATIENT_PROCEDURE";
    PermissionType["PATIENT_INVITATION"] = "PATIENT_INVITATION";
    PermissionType["SUPPLY"] = "SUPPLY";
    PermissionType["TRAVEL"] = "TRAVEL";
    PermissionType["PATIENTS"] = "PATIENTS";
    PermissionType["EDIT_USER"] = "EDIT_USER";
    PermissionType["CONTENTS"] = "CONTENTS";
    PermissionType["DISCOUNTS"] = "DISCOUNTS";
    PermissionType["PCDT"] = "PCDT";
})(PermissionType = exports.PermissionType || (exports.PermissionType = {}));
