import React, { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { t } from '@psp/common';

import { useProgram } from '../../contexts/program.context';
import Select, { SelectProps } from '../Select';
import { useStyles } from './styles';

type PermissionTypeSelectProps = Omit<SelectProps, 'children'>;

export default function PermissionTypeSelect({
  className,
  ...props
}: PermissionTypeSelectProps): JSX.Element {
  const classes = useStyles();
  const { modulesMap } = useProgram();
  const options = useMemo(() => {
    const items = [
      <MenuItem key="empty" value="">
        {' '}
      </MenuItem>,
    ];
    if (modulesMap.clinicalExamination) {
      items.push(
        <MenuItem key="CLINICAL_EXAMINATION" value="CLINICAL_EXAMINATION">
          {t('enum.permissionType.CLINICAL_EXAMINATION')}
        </MenuItem>,
      );
    }
    if (modulesMap.firstMedicine) {
      items.push(
        <MenuItem key="FIRST_MEDICINE" value="FIRST_MEDICINE">
          {t('enum.permissionType.FIRST_MEDICINE')}
        </MenuItem>,
      );
    }
    if (modulesMap.patientInvitation) {
      items.push(
        <MenuItem key="PATIENT_INVITATION" value="PATIENT_INVITATION">
          {t('enum.permissionType.PATIENT_INVITATION')}
        </MenuItem>,
      );
    }
    if (modulesMap.patientProcedure) {
      items.push(
        <MenuItem key="PATIENT_PROCEDURE" value="PATIENT_PROCEDURE">
          {t('enum.permissionType.PATIENT_PROCEDURE')}
        </MenuItem>,
      );
    }
    if (modulesMap.supply) {
      items.push(
        <MenuItem key="SUPPLY" value="SUPPLY">
          {t('enum.permissionType.SUPPLY')}
        </MenuItem>,
      );
    }
    if (modulesMap.travel) {
      items.push(
        <MenuItem key="TRAVEL" value="TRAVEL">
          {t('enum.permissionType.TRAVEL')}
        </MenuItem>,
      );
    }
    if (modulesMap.patients) {
      items.push(
        <MenuItem key="PATIENTS" value="PATIENTS">
          {t('enum.permissionType.PATIENTS')}
        </MenuItem>,
      );
    }
    if (modulesMap.editUser) {
      items.push(
        <MenuItem key="EDIT_USER" value="EDIT_USER">
          {t('enum.permissionType.EDIT_USER')}
        </MenuItem>,
      );
    }

    if (modulesMap.contents) {
      items.push(
        <MenuItem key="CONTENTS" value="CONTENTS">
          {t('enum.permissionType.CONTENTS')}
        </MenuItem>,
      );
    }
    if (modulesMap.discounts) {
      items.push(
        <MenuItem key="DISCOUNTS" value="DISCOUNTS">
          {t('enum.permissionType.DISCOUNTS')}
        </MenuItem>,
      );
    }
    if (modulesMap.PCDT) {
      items.push(
        <MenuItem key="PCDT" value="PCDT">
          {t('enum.permissionType.PCDT')}
        </MenuItem>,
      );
    }

    return items;
  }, [modulesMap]);
  return (
    <Select label={t('permissionType')} className={clsx(className, classes.formControl)} {...props}>
      {options}
    </Select>
  );
}
