import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Modal,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Program } from '@psp/common';
import { SIGNIN_ROUTE, SIGNUP_ROUTE } from '../../constants';
import { ActiveProgramOptionsEnum } from './programOptionsEnum';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    width: '800px',
    height: '400px',
    top: '50%',
    left: '50%',
    backgroundColor: '#ffffff45',
    boxShadow: theme.shadows[24],
    padding: theme.spacing(2),
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    overflow: 'hidden',
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '0 0 auto',
    textAlign: 'center',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
  card: {
    border: '1px solid #ddd',
    height: '240px',
    borderRadius: '12px',
    textAlign: 'center',
    boxShadow: theme.shadows[1],
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  logo: {
    maxWidth: '80px',
    maxHeight: '40px',
    marginBottom: theme.spacing(1),
    objectFit: 'contain',
    margin: 'auto',
  },
  logoFooter: {
    maxWidth: '80px',
    maxHeight: '40px',
    marginTop: theme.spacing(3),
    objectFit: 'contain',
    margin: 'auto',
  },
  button: {
    marginTop: '20px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    width: '100%',
    height: '40px',
    border: '1px solid blue',
    fontSize: '12px',
  },
  boxContent: {
    height: '100px',
  },
  titleContainer: {
    position: 'relative',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    color: theme.palette.grey[800],
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.palette.grey[800],
      backgroundColor: '#ffffff3b',
    },
  },
}));

export default function ProgramsOptionsModal({
  open,
  setProgramsOptionsModalOpen,
  programs,
  route,
}: {
    open: boolean
    setProgramsOptionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    programs: Program[],
    route: 'login' | 'signup',
}) {
  const classes = useStyles();
  const { push } = useHistory();
  const handleProgramLoginNavigate = (programSlug: string) => {
    const pushRoute = route === 'login' ? SIGNIN_ROUTE : SIGNUP_ROUTE;
    return push(`${pushRoute}?program=${programSlug}`);
  };

  const [validPrograms, setValidPrograms] = useState<Program[]>([]);

  useEffect(() => {
    const activeProgramsOptions = Object.values(ActiveProgramOptionsEnum)
      .map((value) => value.toUpperCase());
    const validProgramsArray: Program[] = [];

    programs.forEach((program) => {
      const programIsActive = activeProgramsOptions
        .find((value) => value === program.slug?.toUpperCase());
      if (programIsActive) validProgramsArray.push(program);
    });

    setValidPrograms([...validProgramsArray]);
  }, [programs]);

  return (
    <Modal
      open={open}
      aria-labelledby="program-options-modal"
      aria-describedby="program-options-modal-description"
    >
      <Box className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h5" className={classes.title}>
            Selecione o programa
          </Typography>
          {/* eslint-disable-next-line */}
          <span
            className={classes.closeIcon}
            onClick={() => setProgramsOptionsModalOpen(false)}
          >
            x
          </span>
        </div>
        <Box className={classes.scrollContainer}>
          <Grid container spacing={3} className={classes.gridContainer}>
            {validPrograms?.map((value, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <div className={classes.boxContent}>
                      <img
                        src={`programs-imgs/logo_${value.slug}.png`}
                        alt="Logo"
                        className={classes.logo}
                      />
                      <Typography variant="h4" gutterBottom>
                        {value.name}
                      </Typography>
                    </div>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => handleProgramLoginNavigate(value.slug ?? '')}
                    >
                      Acessar Sistema
                    </Button>
                    <div className={classes.logoFooter}>
                      <img
                        src={`programs-imgs/logo_${value.slug}_footer.png`}
                        alt="Logo"
                        className={classes.logo}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}
