import React from 'react';
import { dt, t, Procedure, User, ProcedureType, Product, formatCPF, ExamGroup } from '@psp/common';

import { PROCEDURE_DETAIL_ROUTE } from '../../constants';
import Table, { TableProps } from '../Table';
import ProcedureStatusChip from '../ProcedureStatusChip';
import PatientOrDoctorColumn from '../PatientOrDoctorColumn';
import MultiDataBox from '../MultiDataBox';
import { useAuth } from '../../contexts/auth.context';

const CompareByEquality = (column: keyof {
    id: string;
    [key: string]: unknown;
}) => (a: {
    id: string;
    [key: string]: unknown;
}, b: {
    id: string;
    [key: string]: unknown;
}) => {
  if (a[column] === b[column]) {
    return 0;
  }
  return 1;
};

function renderColumns(pType: string, isDoctor: boolean) {
  if (pType === ProcedureType.CLINICAL_EXAMINATION) {
    const columns = [
      {
        key: 'createdBy',
        label: '',
        compareFunction: CompareByEquality('supplyGroup'),
        maxWidth: 200,
        render: function PatientOrDoctorRender(value: any, item: Procedure) {
          return <PatientOrDoctorColumn procedure={item} />;
        },
      },
      {
        key: 'createdAt',
        label: '',
        compareFunction: CompareByEquality('supplyGroup'),
        render: function MultiDataBoxRender(value: any, item: Procedure) {
          return <MultiDataBox procedure={item} />;
        },
      },
    ];
    return columns;
  }
  if (pType === ProcedureType.SUPPLY) {
    const columns = [
      {
        key: 'createdBy',
        label: '',
        compareFunction: CompareByEquality('supplyGroup'),
        maxWidth: 200,
        render: function PatientOrDoctorRender(value: any, item: Procedure) {
          return <PatientOrDoctorColumn procedure={item} />;
        },
      },
      {
        key: 'createdAt',
        label: '',
        compareFunction: CompareByEquality('supplyGroup'),
        render: function MultiDataBoxRender(value: any, item: Procedure) {
          return <MultiDataBox procedure={item} />;
        },
      },
    ];
    return columns;
  }
  if (pType === ProcedureType.PATIENT_PROCEDURE) {
    const columns = [
      {
        key: 'createdAt',
        label: t('name'),
        compareFunction: CompareByEquality('createdAt'),
        render: function NameRender(value: string, item: any) {
          return isDoctor ? item.patient?.name : item.createdBy.name;
        },
      },
      {
        key: 'createdBy',
        label: isDoctor ? t('cpf') : t('crm'),
        compareFunction: CompareByEquality('createdBy'),
        render: function NameRender(value: string, item: any) {
          return isDoctor ? formatCPF(item.patient?.cpf) : `${item.createdBy.doctor.crm}/${item.createdBy.doctor.uf}`;
        },
      },
      {
        key: 'product',
        label: t('product'),
        compareFunction: CompareByEquality('product'),
        render: function ProcedureStatusRender(value: Product) {
          return value != null ? value.name : '-';
        },
      },
      {
        key: 'type',
        label: t('procedureTypeColumn'),
        compareFunction: CompareByEquality('type'),
        render: (value: string, item: Procedure) => {
          const type = item.type === ProcedureType.FIRST_MEDICINE
            ? item.product?.name || ''
            : item.type === ProcedureType.CLINICAL_EXAMINATION
              ? item.examGroup?.name || ''
              : item.patientProcedure?.name || '';
          return type;
        },
      },
      {
        key: 'scheduledAt',
        label: t('expectedDateColumn'),
        compareFunction: CompareByEquality('scheduledAt'),
        render: function ProcedureStatusRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
      {
        key: 'concludedAt',
        label: t('concludedDateColumn'),
        compareFunction: CompareByEquality('concludedAt'),
        render: function ProcedureStatusRender(value: Date) {
          return value != null ? dt(value) : '-';
        },
      },
      {
        key: 'status',
        label: t('status'),
        compareFunction: CompareByEquality('status'),
        render: function ProcedureStatusRender(value: any) {
          return <ProcedureStatusChip status={value} />;
        },
      },
    ];
    return columns;
  }
  if (pType === ProcedureType.TRAVEL) {
    const columns = [
      {
        key: 'createdBy',
        label: '',
        compareFunction: CompareByEquality('createdBy'),
        maxWidth: 200,
        render: function PatientOrDoctorRender(value: any, item: Procedure) {
          return <PatientOrDoctorColumn procedure={item} />;
        },
      },
      {
        key: 'createdAt',
        label: '',
        compareFunction: CompareByEquality('createdAt'),
        render: function MultiDataBoxRender(value: any, item: Procedure) {
          return <MultiDataBox procedure={item} />;
        },
      },
    ];
    return columns;
  }

  const columns = [
    {
      key: 'createdAt',
      label: t('requestDate'),
      compareFunction: CompareByEquality('createdAt'),
      render: (value: Date) => dt(value),
    },
    {
      key: 'createdBy',
      label: t('requester'),
      compareFunction: CompareByEquality('createdBy'),
      render: (value: User) => value.name,
    },
    {
      key: 'type',
      label: t('type'),
      compareFunction: CompareByEquality('type'),
      render: (value: string, item: Procedure) => {
        const type = item.type === ProcedureType.FIRST_MEDICINE
          ? item.product?.name || ''
          : item.type === ProcedureType.CLINICAL_EXAMINATION
            ? item.examGroup?.name || ''
            : item.patientProcedure?.name || '';
        return `${t(`procedureType.${value}`)}${type ? ` - ${type}` : ''}`;
      },
    },
    {
      key: 'status',
      label: t('status'),
      compareFunction: CompareByEquality('status'),
      render: function ProcedureStatusRender(value: any) {
        return <ProcedureStatusChip status={value} />;
      },
    },
    {
      key: 'approvedBy',
      label: t('approvalRejection'),
      compareFunction: CompareByEquality('approvedBy'),
      render: function ProcedureApprovedByRender(value: User, item: Procedure) {
        return value && value.id !== item.createdBy.id && item.approvedAt
          ? `${dt(item.approvedAt)} - ${value.name}`
          : '-';
      },
    },
  ];
  return columns;
}

export type ProcedureTableProps = {
  data: Procedure[];
} & Pick<
  TableProps,
  | 'canSearch'
  | 'count'
  | 'onChangePage'
  | 'onChangeRows'
  | 'onSearch'
  | 'page'
  | 'rowsPerPage'
  | 'title'
  | 'viewRoute'
  | 'enableFollowupFilter'
  | 'followupFilterMultiSelect'
  | 'onChangeFollowupfilter'
  | 'enableStatusFilter'
  | 'statusFilterMultiSelect'
  | 'onChangeStatusfilter'
  | 'enableExamGroupFilter'
  | 'examGroupFilterMultiSelect'
  | 'onChangeExamGroupfilter'
  | 'enableDateFilter'
  | 'dateFilterMultiSelect'
  | 'onChangeDatefilter'
>;

export default function ProcedureTable({
  data,
  viewRoute,
  enableFollowupFilter = false,
  followupFilterMultiSelect = false,
  onChangeFollowupfilter = undefined,
  enableStatusFilter = false,
  statusFilterMultiSelect = false,
  onChangeStatusfilter = undefined,
  enableExamGroupFilter = false,
  examGroupFilterMultiSelect = false,
  onChangeExamGroupfilter = undefined,
  enableDateFilter = false,
  dateFilterMultiSelect = false,
  onChangeDatefilter = undefined,
  ...rest
}: ProcedureTableProps): JSX.Element {
  const { isDoctor } = useAuth();
  return (
    <Table
      columns={renderColumns(data[0]?.type, isDoctor)}
      data={data}
      title={t('procedures')}
      size="small"
      canView
      viewRoute={viewRoute || PROCEDURE_DETAIL_ROUTE}
      enableFollowupFilter={enableFollowupFilter}
      followupFilterMultiSelect={followupFilterMultiSelect}
      onChangeFollowupfilter={onChangeFollowupfilter}
      enableStatusFilter={enableStatusFilter}
      statusFilterMultiSelect={statusFilterMultiSelect}
      onChangeStatusfilter={onChangeStatusfilter}
      enableExamGroupFilter={enableStatusFilter}
      examGroupFilterMultiSelect={statusFilterMultiSelect}
      onChangeExamGroupfilter={onChangeExamGroupfilter}
      enableDateFilter={enableStatusFilter}
      dateFilterMultiSelect={statusFilterMultiSelect}
      onChangeDatefilter={onChangeDatefilter}
      {...rest}
    />
  );
}
