/* eslint-disable */

import React, { useState } from 'react';
import { Typography, Container, Box, Grid, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import {
  DownloadProgramFile,
  DeleteProgramFile,
  GetProgramFiles,
  GetProgramProducts,
  GetProductPresentations,
  GetProductPatientProcedures,
  GetProgramFollowups,
  GetFollowupExamGroups,
  GetExamGroupExams,
  GetProgramSupplyGroups,
  GetSupplyGroupSupplies,
  CreateProgramFile,
} from '@psp/common';

import useStyles from './styles';

const noticias = [
  {
    title: "Existem três tipos principais de diabetes, saiba quais são:",
    content: "O diabetes pode ser um inimigo silencioso da sua saúde. Isso porque é pouco sintomático, ou seja, os sintomas do diab...",
    date: "05/04/2024 às 9h30",
    fileName: 'o-que-e-diabetes',
  },
  {
    title: "Hoje, existem diversos tratamentos disponíveis para o câncer de Pulmão",
    content: "O câncer de pulmão é uma doença que pode iniciar nas células que revestem os brônquios e partes do pulmão, como os b...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "Doença Pulmonar Intersticial",
    content: "O diagnóstico das DPIs é bastante desafiador uma vez que os sintomas apresentados são parecidos com os de o...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "O que é Psoríase Pustulosa Generalizada (PPG)?",
    content: "A PPG é uma doença rara. Crises aparecem de uma hora para outra na forma de dolorosas e pequenas bolhas cheias de...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "A bombinha da Asma vicia e faz mal para o coração?",
    content: "Em relação ao coração, os primeiros remédios broncodilatadores para asma eram substâncias que tinham como efeito...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "Doença Pulmonar Obstrutiva Crônica",
    content: "O cigarro é o fator mais importante no desenvolvimento e progresso da DPOC. A gravidade da...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "Existem três tipos principais de diabetes, saiba quais são:",
    content: "O diabetes pode ser um inimigo silencioso da sua saúde. Isso porque é pouco sintomático, ou seja, os sintomas do diab...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "Hoje, existem diversos tratamentos disponíveis para o câncer de Pulmão",
    content: "O câncer de pulmão é uma doença que pode iniciar nas células que revestem os brônquios e partes do pulmão, como os b...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "Doença Pulmonar Intersticial",
    content: "O diagnóstico das DPIs é bastante desafiador uma vez que os sintomas apresentados são parecidos com os de o...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "O que é Psoríase Pustulosa Generalizada (PPG)?",
    content: "A PPG é uma doença rara. Crises aparecem de uma hora para outra na forma de dolorosas e pequenas bolhas cheias de...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "A bombinha da Asma vicia e faz mal para o coração?",
    content: "Em relação ao coração, os primeiros remédios broncodilatadores para asma eram substâncias que tinham como efeito...",
    date: "05/04/2024 às 9h30",
  },
  {
    title: "Doença Pulmonar Obstrutiva Crônica",
    content: "O cigarro é o fator mais importante no desenvolvimento e progresso da DPOC. A gravidade da...",
    date: "05/04/2024 às 9h30",
  },
];

export type ContentsProps = {
  getProgramFiles: GetProgramFiles;
  downloadProgramFile: DownloadProgramFile
  deleteProgramFile: DeleteProgramFile;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
  getProductPatientProcedures: GetProductPatientProcedures;
  getProgramFollowups: GetProgramFollowups;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  getProgramSupplyGroups: GetProgramSupplyGroups;
  getSupplyGroupSupplies: GetSupplyGroupSupplies;
  createProgramFile: CreateProgramFile;
};

const ContentsComponent: React.FC<any> = ({
}: ContentsProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNews, setFilteredNews] = useState(noticias);
  const [isFiltred, setIsFiltred] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedNews = showAll ? filteredNews : filteredNews.slice(0, 6);

  const handleSearchChange = (event: { target: { value: string; }; }) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = noticias.filter(news =>
      news.title.toLowerCase().includes(term) || news.content.toLowerCase().includes(term)
    );

    console.log(term);
    setIsFiltred(term != '' && filtered.length > 0);
    setFilteredNews(filtered);
  };

  return (
    <>
      <Container>
        <Box my={1} py={1}>
          <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
            Conteúdos
          </Typography>
          <hr style={{ border: '1px solid lightgray', margin: '24px 0' }} />

          <Typography variant="h6" style={{ textAlign: 'left', marginBottom: '12px' }}>
            Aqui é possível encontrar informações seguras e de qualidade para auxiliar no conhecimento e na jornada das especialidades contempladas no programa.
          </Typography>

          <Box my={2} py={2}>
            <TextField
              fullWidth
              placeholder="Busque e explore nosso banco de conteúdos"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ backgroundColor: '#fff' }}
              InputProps={{
                startAdornment:
                  <Box>
                    <SearchOutlined />
                  </Box>
              }}
            />
          </Box>
          <Grid container spacing={5}>
            {displayedNews.map((news, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link to={`/contents/${news.fileName}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>
                    {news.title}
                  </Typography>
                  <Typography variant="body2" style={{ color: '#556275' }}>
                    {news.content}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>

        {!isFiltred && filteredNews.length > 6 && (
          <Box my={2} py={2}>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography variant="body1" onClick={toggleShowAll} style={{ fontWeight: 'bold', color: 'var(--primary-main)', cursor: 'pointer', marginBottom: '64px' }}>
                  {showAll ? 'Exibir menos' : 'Exibir mais'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ContentsComponent;
