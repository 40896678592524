import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Input as MaterialInput,
  FormControl,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { differenceInSeconds } from 'date-fns';
import { toast } from 'react-toastify';
import useDigitInput from 'react-digit-input';
import {
  ExamGroup,
  GetDoctorInventoryAmounts,
  GetExamGroupExams,
  GetFollowupExamGroups,
  GetInventoryAmount,
  GetProgramFollowups,
  GetProgramPathologies,
  Inventory,
  InventoryType,
  ProcedureAgreementType,
  ProcedureType,
  RegisterProcedure,
  RegisterProcedureParams,
  SendVerificationCode,
  ValidationError,
  examRequestForm,
  extractValidationErrors,
  t,
  GetPatientFields,
  GetProcedureFields,
  getAddressByCEP,
  Exam,
  Institution,
  getCitiesByState,
  ProcedureLogisticType,
  GetProductPresentations,
  GetProgramProducts,
} from '@psp/common';

import { useLoading } from '../../../contexts/loading.context';
import { useProgram } from '../../../contexts/program.context';
import { EXAM_REQUEST_ROUTE } from '../../../constants';
import PageTitle from '../../PageTitle';
import PageSubtitle from '../../PageSubtitle';
import ExamGroupSelect, { ExamGroupSelectValues } from '../../ExamGroupSelect';
import Select from '../../Select';
import Input from '../../Input';
import CheckboxInput from '../../CheckboxInput';
import ExamMultiSelect from '../../ExamMultiSelect';
import GenderSelect from '../../GenderSelect';
import DateInput from '../../DateInput';
import DocumentInput from '../../DocumentInput';
import Button from '../../Button';
import MaskedPhoneInput from '../../MaskedPhoneInput';
import ProcedureAgreementTypeSelect from '../../ProcedureAgreementTypeSelect';
import Base64FileUploadInput from '../../Base64FileUploadInput';
import DynamicProcedreField from '../../DynamicProcedureField';
import DynamicAidFieldTemplate from '../../DynamicAidField';

import { useStyles } from './styles';
import PaperTitle from '../../PaperTitle';
import PaperContent from '../../PaperContent';
import { useAuth } from '../../../contexts/auth.context';
import { Value } from '../../CheckboxGroup';
import UnselectedExamsDialog from '../UnselectedExamsDialog';
import DocumentTypeSelect from '../../DocumentTypeSelect';
import DocumentCnpjInput from '../../DocumentCnpjInput';
import StateSelect from '../../StateSelect';
import ProcedureLogisticTypeSelect from '../../ProcedureLogisticTypeSelect';
import ProductSelect from '../../ProductSelect';

export type ExamRequestProps = {
  getInventoryAmount: GetInventoryAmount;
  getProgramFollowups: GetProgramFollowups;
  getProgramPathologies: GetProgramPathologies;
  getFollowupExamGroups: GetFollowupExamGroups;
  getExamGroupExams: GetExamGroupExams;
  getDoctorInventoryAmounts: GetDoctorInventoryAmounts;
  registerProcedure: RegisterProcedure;
  sendVerificationCodeAuthenticated: SendVerificationCode;
  getPatientFields: GetPatientFields;
  getProcedureFields: GetProcedureFields;
  getProgramProducts: GetProgramProducts;
  getProductPresentations: GetProductPresentations;
};

type ExamRequestState = {
  examGroupId?: string;
  examGroup?: ExamGroup;
  patientFieldConfig?: any[];
  procedureFieldConfig?: any[];
  allCitiesBySelectedState: any[];
  inventory: {
    [key: string]: Inventory;
  };
  procerdureAgreementType?: ProcedureAgreementType;
  procerdureLogisticType?: ProcedureLogisticType;
  hasSupplyRequest: boolean;
  exams?: Exam[];
  showUnselectedExamsDialog: boolean;
  recipientTypes: string[];
  presentationEan?: string;
};

const defaultState: ExamRequestState = {
  inventory: {},
  hasSupplyRequest: false,
  showUnselectedExamsDialog: false,
  allCitiesBySelectedState: [],
  recipientTypes: ['PATIENT', 'CLINIC'],
};

export default function ExamRequest({
  getInventoryAmount,
  registerProcedure,
  getDoctorInventoryAmounts,
  sendVerificationCodeAuthenticated,
  getPatientFields,
  getProcedureFields,
  getProgramPathologies,
  ...dispatchers
}: ExamRequestProps): JSX.Element {
  const classes = useStyles();
  const formRef = useRef({} as any);
  const patientStateRef: any = useRef(null);
  const { isLoading, showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();
  const { program } = useProgram();
  const [code, setCode] = useState('');
  const [codeText, setCodeText] = useState('');
  const [codeError, setCodeError] = useState('');
  const [documentTypeOfSampleWithdrawal,
    setDocumentTypeOfSampleWithdrawal] = useState('');
  const [sendDate, setSendDate] = useState<Date | undefined>(undefined);
  const [state, setState] = useState<ExamRequestState>(defaultState);
  const { user } = useAuth();
  const institutionRef: any = useRef(null);

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 5,
    value: code,
    onChange: setCode,
  });

  const handleExamGroupChange = useCallback(
    (values: ExamGroupSelectValues) => {
      if (!values.examGroupId) return;
      showLoading();
      (async () => {
        try {
          const patientFields = await getPatientFields.execute({ groupId: values.examGroupId });
          const procedureFields = await getProcedureFields.execute({ groupId: values.examGroupId });
          const res = await getDoctorInventoryAmounts.execute({
            programId: program!.id,
            userId: user!.userId,
            inventory: InventoryType.CLINICAL_EXAMINATION,
            groupId: values.examGroupId,
          });

          setState({
            ...state,
            allCitiesBySelectedState: [],
            examGroupId: values.examGroupId,
            examGroup: values.examGroup,
            patientFieldConfig: patientFields,
            procedureFieldConfig: procedureFields,
            recipientTypes: values.examGroup?.sampleWithdrawalRecipientTypes.split(',') ?? [],
            inventory: res.doctor.reduce(
              (prev, iv) => ({
                ...prev,
                [iv.itemId || iv.groupId]: iv,
              }),
              {},
            ),
          });
          setCode('');
          setCodeText('');
          setCodeError('');
          setSendDate(undefined);
        } catch (err) {
          formRef.current.reset();
          toast.error(t('err.failedToLoadInventory'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } finally {
          hideLoading();
        }
      })();
    },
    [state, program, user],
  );

  const handleProcedureAgreementTypeChange = useCallback(
    (value) => {
      setState({
        ...state,
        procerdureAgreementType: value,
      });
    },
    [state],
  );

  const handleProcedureLogisticTypeChange = useCallback(
    (value) => {
      setState({
        ...state,
        procerdureLogisticType: value,
      });
    },
    [state],
  );

  const handleSendVerificationClick = useCallback(() => {
    showLoading();
    async function runAsync() {
      try {
        const data = await formRef.current.getData();
        const req = ((await examRequestForm.validate(
          {
            ...data,
            type: ProcedureType.CLINICAL_EXAMINATION,
            programId: (program || {}).id,
          },
          {
            abortEarly: false,
            context: {
              userAddress: data.userAddress,
              requiresSampleWithdrawal: state.examGroup?.requiresSampleWithdrawal,
              documentTypeOfSampleWithdrawal,
              requireAddress: state.examGroup?.requiredAddress,
            },
          },
        )) as unknown) as RegisterProcedureParams;
        const res = await sendVerificationCodeAuthenticated.execute({
          type: 'SMS',
          destination: req.patient.phone || '',
          patientAgreementUrl: state.examGroup?.patientAgreementUrl,
        });
        setSendDate(res);
      } catch (err) {
        let errors: any = {};
        if (err instanceof ValidationError) {
          errors = extractValidationErrors(err);
          formRef.current.setErrors(errors);
        } else if (err.name === 'BadRequestError') {
          toast.info(err.message, {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        } else {
          toast.info(t('err.failedToSendCode'), {
            onOpen: showBackdrop,
            onClose: hideBackdrop,
          });
        }
      } finally {
        hideLoading();
      }
    }
    runAsync();
  }, [sendVerificationCodeAuthenticated, formRef, state]);

  const handleSendAgainClick = useCallback((): void => {
    handleSendVerificationClick();
  }, [handleSendVerificationClick]);

  const setErrorMensages = (data: any, extractedErrors: any = {}): boolean => {
    let isValid = true;
    const validationErrors = {} as any;

    state.patientFieldConfig?.forEach((field) => {
      if (
        field.visible
        && field.required
        && (data.patient[field.field] === undefined
          || data.patient[field.field] === null
          || data.patient[field.field] === ''
          || data.patient[field.field] === false)
      ) {
        validationErrors[`patient.${field.field}`] = t('validation.requiredField');
        isValid = false;
      }
    });

    state.procedureFieldConfig?.forEach((field) => {
      if (
        field.active
        && field.viewType === 'required'
        && (!data.procedureFieldsAnswers
          || data.procedureFieldsAnswers[field.name] === undefined
          || data.procedureFieldsAnswers[field.name] === ''
          || data.procedureFieldsAnswers[field.name] === null
          || data.procedureFieldsAnswers[field.name] === false
          || data.procedureFieldsAnswers[field.name].length === 0)
      ) {
        validationErrors[`procedureFieldsAnswers.${field.name}`] = t('validation.requiredField');
        isValid = false;
      }

      if (
        field.min !== null
        && field.min !== ''
        && data.procedureFieldsAnswers
        && data.procedureFieldsAnswers[field.name] !== null
        && data.procedureFieldsAnswers[field.name].length < field.min
      ) {
        const length = field.min;
        validationErrors[`procedureFieldsAnswers.${field.name}`] = t('validation.minLength', {
          length,
        });
        isValid = false;
      }

      if (
        field.requiredAnswers !== null
        && field.requiredAnswers !== ''
        && data.procedureFieldsAnswers
        && data.procedureFieldsAnswers[field.name] !== null
        && data.procedureFieldsAnswers[field.name].toString() !== field.requiredAnswers
      ) {
        let answer = field.requiredAnswers;

        if (field.requiredAnswers === 'true') {
          answer = 'Sim';
        }
        if (field.requiredAnswers === 'false') {
          answer = 'Não';
        }

        validationErrors[`procedureFieldsAnswers.${field.name}`] = t(
          'validation.requiredSelect',
        );
        isValid = false;
      }
    });

    formRef.current.setErrors({ ...validationErrors, ...extractedErrors });
    return isValid;
  };

  const closeUnselectedExamsDialog = (): void => {
    setState({
      ...state,
      showUnselectedExamsDialog: false,
    });
  };

  const confirmUnselectedExamsDialog = (): void => {
    formRef.current.submitForm();
  };

  const handleSubmit = useCallback(
    (data: any): void => {
      if (state.showUnselectedExamsDialog) {
        closeUnselectedExamsDialog();
      } else if (state.examGroup?.showUnselectedExamsWarning) {
        if ((state.exams ?? []).length > data.itemIds.length) {
          setState({
            ...state,
            showUnselectedExamsDialog: true,
          });
          return;
        }
      }

      showLoading();
      formRef.current.setErrors({});
      (async () => {
        try {
          // eslint-disable-next-line
          data.presentationEan = state.presentationEan;

          const req = ((await examRequestForm.validate(
            {
              ...data,
              type: ProcedureType.CLINICAL_EXAMINATION,
              programId: (program || {}).id,
              requirePatientAgreement: state.examGroup?.requirePatientAgreement,
              code,
            },
            {
              abortEarly: false,
              context: {
                userAddress: data.userAddress,
                requiresSampleWithdrawal: state.examGroup?.requiresSampleWithdrawal,
                documentTypeOfSampleWithdrawal,
                hasSupplyRequest: state.hasSupplyRequest,
                logisticType: state.procerdureLogisticType,
                requiredProduct: state.examGroup?.requiredProduct,
                requiredPersonalInformation: state.examGroup?.requiredPersonalInformation,
                requireAddress: state.examGroup?.requiredAddress,
              },
            },
          )) as unknown) as RegisterProcedureParams;
          if (setErrorMensages(data)) {
            req.userAddress = data.userAddress;
            await registerProcedure.execute(req);
            let successMessage = t('msg.successToRequestExams');
            if (state.procerdureLogisticType === ProcedureLogisticType.CORREIOS) {
              successMessage = t('msg.successToRequestExamsSendCodeIn24Hours');
            }
            toast.success(successMessage, {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
            formRef.current.reset();
            setState({
              ...defaultState,
            });
            setCode('');
            setCodeText('');
            setCodeError('');
            setSendDate(undefined);
          }
        } catch (err) {
          console.log(err);
          if (err instanceof ValidationError) {
            setErrorMensages(data, extractValidationErrors(err));
          }

          if (err.message === 'existingProcedure') {
            toast.info(t('err.existingPatientProcedure'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'eligibilityRejected') {
            const examGroupName = state.examGroup?.name;
            if (examGroupName === 'Elegibilidade Pluvicto') {
              toast.info(t('err.eligibilityRejectedUnder18'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else {
              toast.info(t('err.eligibilityRejected'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          } else if (err.message === 'insufficientSupplies') {
            toast.info(t('err.insufficientSupplies'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else if (err.message === 'fillAllLaboratoryFields') {
            toast.info(t('err.fillAllLaboratoryFields'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors: any = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              formRef.current.setErrors(errors);
              if (errors.code) {
                setCodeError(errors.code);
              }
            } else if (err.message === 'invalidCode') {
              toast.info(t(`err.${err.message}`), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else if (err.name === 'BadRequestError') {
              toast.info(err.message, {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else if (err.message === 'request entity too large') {
              toast.info(t('err.requestEntityTooLarge'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            } else {
              toast.info(t('err.failedToRequestExams'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      })();
    },
    [program, state, code],
  );

  useEffect(() => {
    let handler: any;
    if (sendDate) {
      handler = setInterval(() => {
        const count = Math.max(60 - differenceInSeconds(new Date(), sendDate), 0);
        setCodeText(t('sendAgainIn', { count }));
      }, 1000);
    }
    return function cleanup() {
      clearInterval(handler);
    };
  }, [sendDate, setCodeText]);

  const handleStateChange = async (value: string) => {
    showLoading();
    (async () => {
      try {
        const allCitiesBySelectedState = await getCitiesByState.execute({ state: value });

        setState({ ...state,
          allCitiesBySelectedState,
        });
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
        if (patientStateRef.current) {
          patientStateRef.current.focus();
        }
      }
    })();
  };

  const buildPatientFields = useCallback(() => {
    if (!state.patientFieldConfig) return <></>;
    const fields = [];
    const fieldMap = state.patientFieldConfig
      .filter((x) => x.visible)
      .reduce((p, c) => ({ ...p, [c.field]: c }), {});
    if (fieldMap.birthDate && fieldMap.birthDate.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <DateInput
            name="patient.birthDate"
            inputVariant="outlined"
            label={t('birthDate')}
            format="dd/MM/yyyy"
            optional
            className={classes.input}
            disabled={isLoading}
          />
        </Grid>,
      );
    }
    if (fieldMap.rg && fieldMap.rg.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.rg"
            variant="outlined"
            label={t('rg')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="nope"
          />
        </Grid>,
      );
    }
    fields.push(
      <Grid item xs={12} sm={4} lg={3} xl={2}>
        <Input
          name="patient.phone"
          variant="outlined"
          label={t('contactCellphone')}
          className={classes.input}
          disabled={isLoading}
          autoComplete="nope"
          InputProps={{
            inputComponent: MaskedPhoneInput as any,
          }}
        />
      </Grid>,
    );
    if (fieldMap.cep && fieldMap.cep.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.cep"
            variant="outlined"
            label={t('postalCode')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="nope"
          />
        </Grid>,
      );
    }
    if (fieldMap.initials && fieldMap.initials.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.initials"
            variant="outlined"
            label={t('nameInitials')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="nope"
          />
        </Grid>,
      );
    }
    if (fieldMap.caregiverName && fieldMap.caregiverName.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.caregiverName"
            variant="outlined"
            label={t('caregiverName')}
            className={classes.input}
            disabled={isLoading}
            optional={!fieldMap.caregiverName.required}
            autoComplete="nope"
          />
        </Grid>,
      );
    }
    if (fieldMap.email && fieldMap.email.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Input
            name="patient.email"
            variant="outlined"
            label={t('email')}
            className={classes.input}
            disabled={isLoading}
            autoComplete="nope"
          />
        </Grid>,
      );
    }
    if (fieldMap.state && fieldMap.state.visible) {
      fields.push(
        <Grid item xs={12} sm="auto">
          <StateSelect
            name="patient.state"
            variant="outlined"
            label={t('uf')}
            fullWidth
            displayEmpty
            className={classes.input}
            disabled={isLoading}
            onSelectedValueChange={handleStateChange}
          />
        </Grid>,
      );
    }
    if (fieldMap.city && fieldMap.city.visible) {
      fields.push(
        <Grid item xs={12} sm={4} lg={3} xl={2}>
          <Select
            name="patient.city"
            label={t('city')}
            variant="outlined"
            fullWidth
            disabled={state?.allCitiesBySelectedState?.length === 0 || isLoading}
            inputRef={patientStateRef}
          >
            {state?.allCitiesBySelectedState?.map((p) => (
              <MenuItem key={p.id} value={p.city}>
                {p.city}
              </MenuItem>
            ))}
          </Select>
        </Grid>,
      );
    }

    return fields;
  }, [state.patientFieldConfig, isLoading]);

  const buildAidFields = useCallback(() => {
    if (!state.examGroup?.aids?.length) return <></>;

    const fields = state.examGroup?.aids?.map((aid) => (
      <Grid key={aid?.type} item xs={12} sm>
        {aid.aidFields.map((aidField) => (
          <DynamicAidFieldTemplate
            key={aidField.id}
            name={`aidFields.${aidField.id}`}
            label={aidField.label}
            fieldType={aidField.fieldType}
            active
          />
        ))}
      </Grid>
    ));

    return fields;
  }, [state.examGroup?.aids, isLoading]);

  const buildPatientAcceptFields = useCallback(() => {
    if (!state.patientFieldConfig) return <></>;
    const fields = [];
    const fieldMap = state.patientFieldConfig
      .filter((x) => x.visible)
      .reduce((p, c) => ({ ...p, [c.field]: c }), {});
    if (fieldMap.acceptSms && fieldMap.acceptSms.visible) {
      fields.push(
        <CheckboxInput
          name="patient.acceptSms"
          label={t('pages.exam.request.acceptSms')}
          disabled={isLoading}
        />,
      );
    }
    if (fieldMap.acceptPhone && fieldMap.acceptPhone.visible) {
      fields.push(
        <CheckboxInput
          name="patient.acceptPhone"
          label={t('pages.exam.request.acceptPhone')}
          disabled={isLoading}
        />,
      );
    }
    if (fieldMap.acceptEmail && fieldMap.acceptEmail.visible) {
      fields.push(
        <CheckboxInput
          name="patient.acceptEmail"
          label={t('pages.exam.request.acceptEmail')}
          disabled={isLoading}
        />,
      );
    }

    return fields;
  }, [state.patientFieldConfig, isLoading]);

  const buildBottomProcedureFields = useCallback(() => {
    if (!state.procedureFieldConfig) return <></>;
    const fieldGroups = state.procedureFieldConfig
      .filter((x) => x.active && x.showAtBottom)
      .reduce((p, c) => ({
        ...p,
        [c.group ?? 0]: [...(p[c.group ?? 0] ?? []), c],
      }), {});

    const fields = Object.keys(fieldGroups)
      .sort((a, b) => a.localeCompare(b))
      .map((k) => (
        <Grid key={k} item xs={12} sm>
          {
          fieldGroups[k]
            .sort((a: any, b: any) => a.order - b.order)
            .map((x: any) => (
              <DynamicProcedreField
                key={x.id}
                name={`procedureFieldsAnswers.${x.name}`}
                max={x.max}
                mask={x.mask}
                label={x.name}
                fieldType={x.fieldType}
                options={x.options}
                getProgramPathologies={getProgramPathologies}
                active
              />
            ))
          }
        </Grid>
      ));

    return fields;
  }, [state.procedureFieldConfig, isLoading]);

  const buildProcedureField = (field: any): JSX.Element => {
    if (field.active && !field.showAtBottom) {
      return (
        <>
          <Grid key={field.id} item xs={12} sm={4} lg={3} xl={2}>
            <DynamicProcedreField
              name={`procedureFieldsAnswers.${field.name}`}
              max={field.max}
              mask={field.mask}
              label={field.label ?? field.name}
              fieldType={field.fieldType}
              options={field.options}
              getProgramPathologies={getProgramPathologies}
              active
            />
          </Grid>
        </>
      );
    }

    return <></>;
  };

  const handleExamLoad = (exams: Exam[]): void => {
    setState({
      ...state,
      exams,
    });
  };

  const getCommonRecipientTypes = (selectedExams: Value[]): string[] => {
    // Get all recipientTypes
    const recipientTypesArrays = selectedExams.map((se) => se.subvalues?.map((sub) => sub.supplementaryData.recipientTypes.split(',')));

    // Get only common Recipient Types to all exams supplies
    const commonRecipientTypes: string[] = recipientTypesArrays.flat().reduce(
      (previous, current) => previous.filter(
        (recipientType: string) => current.includes(recipientType),
      ),
    );

    return commonRecipientTypes;
  };

  const handleExamChange = (selectedExams: Value[]): void => {
    if (selectedExams.filter((se) => se.subvalues && se.subvalues?.length > 0).length > 0) {
      const recipientTypes = getCommonRecipientTypes(selectedExams);
      setState({
        ...state,
        hasSupplyRequest: true,
        recipientTypes,
      });
    } else {
      setState({
        ...state,
        hasSupplyRequest: false,
        recipientTypes: state.recipientTypes ?? ['PATIENT', 'CLINIC'],
      });
    }
  };

  const hadleDocumentTypeOfSampleWithdrawalChange = (selectedType: any): void => {
    setDocumentTypeOfSampleWithdrawal(selectedType);
  };

  const handleCEPChange = async (value: string) => {
    let cep = value;
    cep = cep.replace(/\D/g, '');

    const data = await formRef.current.getData();
    if (cep.length > 7) {
      cep = cep.substring(0, 8);

      (async () => {
        showLoading();
        try {
          const addressByCEP = await getAddressByCEP.execute({
            cep,
          });

          if (!addressByCEP.erro) {
            formRef.current.setData({
              ...data,
              userAddress: {
                ...data.userAddress,
                cep: addressByCEP.cep,
                placeDescription: addressByCEP.logradouro,
                complement: addressByCEP.complemento,
                neighborhood: addressByCEP.bairro,
                city: addressByCEP.localidade,
                state: addressByCEP.uf,
              },
            });
          } else {
            formRef.current.setData({
              ...data,
              userAddress: {
                cep,
                placeDescription: '',
                number: '',
                complement: '',
                neighborhood: '',
                city: '',
                state: '',
              },
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          hideLoading();
        }
      })();
    } else if (cep.length > 5) {
      cep = `${cep.slice(0, 5)}-${cep.slice(5)}`;

      formRef.current.setData({
        ...data,
        userAddress: {
          ...data.userAddress,
          cep,
        },
      });
    }
  };

  const handlePresentationChange = useCallback(
    (value) => {
      if (!value) return;
      setState({
        ...state,
        ...value,
      });
    },
    [state],
  );

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <PaperTitle title={t('selectExams')} />
        <PaperContent>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sm={8} lg={6} xl={4}>
                <ExamGroupSelect
                  {...dispatchers}
                  onSelectedValueChange={handleExamGroupChange}
                  formRef={formRef}
                  examGroupFieldProps={{
                    name: 'groupId',
                  }}
                />
              </Grid>
              {state.examGroupId && (
                <Grid item>
                  <Grid container spacing={2} direction="column" className={classes.nowrap}>
                    <Grid item>
                      <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={4} lg={3} xl={2}>
                          <Input
                            name="patient.name"
                            variant="outlined"
                            label={t('patientFullName')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="nope"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3} xl={2}>
                          <Input
                            name="patient.cpf"
                            variant="outlined"
                            label={t('patientCpf')}
                            className={classes.input}
                            disabled={isLoading}
                            autoComplete="off"
                            InputProps={{
                              inputComponent: DocumentInput as any,
                            }}
                          />
                        </Grid>
                        {
                          state.examGroup?.requiredPersonalInformation && (
                            <>
                              <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <Input
                                  name="patient.email"
                                  variant="outlined"
                                  label={t('email')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={3} xl={2}>
                                <GenderSelect
                                  name="patient.gender"
                                  variant="outlined"
                                  label={t('gender')}
                                  displayEmpty
                                  className={classes.input}
                                  disabled={isLoading}
                                  optionalValueToWSAcesso
                                />
                              </Grid>
                            </>
                          )
                        }
                        {buildPatientFields()}
                        {state.procedureFieldConfig
                          && state.procedureFieldConfig
                            .sort((a: any, b: any) => a.order - b.order)
                            .map((x) => buildProcedureField(x))}
                      </Grid>
                      {
                        state.examGroup?.requiredProduct && (
                          <>
                            <Grid item xs={12} sm={10}>
                              <ProductSelect
                                formRef={formRef}
                                getProgramProducts={dispatchers.getProgramProducts}
                                getProductPresentations={dispatchers.getProductPresentations}
                                onSelectedValueChange={handlePresentationChange}
                                direction="column"
                                optionalProduct
                                optionalPresentation
                              />
                            </Grid>
                          </>
                        )
                      }
                    </Grid>
                    <Grid item>
                      <ExamMultiSelect
                        examGroupId={state.examGroupId}
                        canRequestWithoutInventory={state.examGroup?.canRequestWithoutInventory}
                        requireApprovalWithoutInventory={
                          state.examGroup?.requireApprovalWithoutInventory
                        }
                        inventory={state.inventory}
                        examsFieldProps={{
                          name: 'itemIds',
                          subvalueName: 'supplies',
                        }}
                        onChange={handleExamChange}
                        onExamsLoad={handleExamLoad}
                        {...dispatchers}
                      />
                    </Grid>
                    {state.examGroup?.requirePatientAgreement && (
                      <Grid item>
                        <Grid container spacing={2} direction="column">
                          <Grid item xs={12} md={4} lg={4}>
                            <ProcedureAgreementTypeSelect
                              name="agreementType"
                              variant="outlined"
                              fullWidth
                              onSelectedValueChange={handleProcedureAgreementTypeChange}
                            />
                          </Grid>
                          {state.procerdureAgreementType === ProcedureAgreementType.FILE && (
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                              <Grid container direction="column" alignItems="center">
                                <Grid item xs style={{ textAlign: 'center' }}>
                                  <Base64FileUploadInput dialogMessage={t('confirmCopy')} name="agreementFile" />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {state.procerdureAgreementType === ProcedureAgreementType.CODE && (
                            <Grid item xs={12} sm={4} lg={3} xl={2}>
                              <Grid container direction="column" alignItems="center">
                                {!sendDate && (
                                  <Grid item xs style={{ textAlign: 'center' }}>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleSendVerificationClick}
                                      disabled={isLoading}
                                    >
                                      {t('sendSmsCode')}
                                    </Button>
                                    {codeError && (
                                      <Typography variant="caption" color="error">
                                        {codeError}
                                      </Typography>
                                    )}
                                  </Grid>
                                )}
                                {!!sendDate && (
                                  <>
                                    <Grid item xs>
                                      <Grid container spacing={0} justify="center">
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[0],
                                              className: classes.input,
                                            }}
                                            autoFocus
                                            className={classes.codeField}
                                            disabled={isLoading}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[1],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[2],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[3],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            inputMode="decimal"
                                            variant="outlined"
                                            inputProps={{
                                              ...digits[4],
                                              className: classes.input,
                                            }}
                                            className={classes.codeField}
                                            disabled={isLoading}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {codeError && (
                                      <Typography variant="caption" color="error">
                                        {codeError}
                                      </Typography>
                                    )}
                                    <br />
                                    <Button
                                      variant="text"
                                      onClick={handleSendAgainClick}
                                      disabled={
                                        isLoading || codeText !== t('sendAgainIn', { count: 0 })
                                      }
                                    >
                                      {codeText}
                                    </Button>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {(state.examGroup?.requiresSampleWithdrawal || state.hasSupplyRequest)
                      && (
                        <Grid item>
                          <Grid container spacing={2} direction="column" className={classes.nowrap}>
                            <Grid item>
                              <Grid container spacing={2} direction="row">
                                <Grid item xs={12} sm={12} lg={12}>
                                  <Typography variant="h6">
                                    {
                                      state.examGroup?.requiresSampleWithdrawal
                                        ? (<strong>Informações para envio da amostra</strong>)
                                        : (<strong>Endereço para envio de kits</strong>)
                                    }
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                  <ProcedureLogisticTypeSelect
                                    name="logisticType"
                                    variant="outlined"
                                    fullWidth
                                    onSelectedValueChange={handleProcedureLogisticTypeChange}
                                  />
                                </Grid>
                                {state.examGroup?.requiresSampleWithdrawal && (
                                  <>
                                    <Grid item xs={12} sm={12} lg={12}>
                                      <Grid item xs={12} md={4} lg={4}>
                                        <Select
                                          name="institutionId"
                                          label={t('targetInstitution')}
                                          variant="outlined"
                                          fullWidth
                                          optional={false}
                                          preSelected={state.examGroup.institutions.length === 1
                                            ? state.examGroup.institutions[0].id : null}
                                        >
                                          {state.examGroup.institutions?.map((p) => (
                                            <MenuItem key={p.id} value={p.id}>
                                              {p.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>
                                    </Grid>
                                    {state.procerdureLogisticType
                                      === ProcedureLogisticType.LOGISTIC_COMPANY && (
                                        <>
                                          <Grid item xs={12} sm={4} lg={4}>
                                            <DateInput
                                              name="userAddress.firstDateForSampleWithdrawal"
                                              inputVariant="outlined"
                                              minDate={new Date().setDate(new Date().getDate() + 2)}
                                              label={t('firstDateForSampleWithdrawal')}
                                              format="dd/MM/yyyy"
                                              optional={state.procerdureLogisticType
                                                !== ProcedureLogisticType.LOGISTIC_COMPANY}
                                              fullWidth
                                              className={classes.input}
                                              disabled={isLoading}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={4} lg={4}>
                                            <DateInput
                                              name="userAddress.secondDateForSampleWithdrawal"
                                              inputVariant="outlined"
                                              minDate={new Date().setDate(new Date().getDate() + 2)}
                                              label={t('secondDateForSampleWithdrawal')}
                                              format="dd/MM/yyyy"
                                              optional
                                              className={classes.input}
                                              disabled={isLoading}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={4} lg={4}>
                                            <DateInput
                                              name="userAddress.thirdDateForSampleWithdrawal"
                                              inputVariant="outlined"
                                              minDate={new Date().setDate(new Date().getDate() + 2)}
                                              label={t('thirdDateForSampleWithdrawal')}
                                              format="dd/MM/yyyy"
                                              optional
                                              className={classes.input}
                                              disabled={isLoading}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={4} lg={4}>
                                            <DateInput
                                              name="userAddress.fourthDateForSampleWithdrawal"
                                              inputVariant="outlined"
                                              minDate={new Date().setDate(new Date().getDate() + 2)}
                                              label={t('fourthDateForSampleWithdrawal')}
                                              format="dd/MM/yyyy"
                                              optional
                                              className={classes.input}
                                              disabled={isLoading}
                                            />
                                          </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} sm={4} lg={3} xl={2}>
                                      <DocumentTypeSelect
                                        name="userAddress.documentType"
                                        variant="outlined"
                                        label={t('documentType')}
                                        fullWidth
                                        displayEmpty
                                        onSelectedValueChange={
                                          hadleDocumentTypeOfSampleWithdrawalChange
                                        }
                                        className={classes.input}
                                        disabled={isLoading}
                                        showOnlyCpf={state.procerdureLogisticType
                                          === ProcedureLogisticType.CORREIOS}
                                      />
                                    </Grid>
                                    {documentTypeOfSampleWithdrawal === 'cnpj'
                                      && (
                                        <>
                                          <Grid item xs={12} sm={4} lg={3} xl={2}>
                                            <Input
                                              name="userAddress.requesterCnpj"
                                              variant="outlined"
                                              label={t('requesterCnpj')}
                                              className={classes.input}
                                              disabled={isLoading}
                                              autoComplete="off"
                                              InputProps={{
                                                inputComponent: DocumentCnpjInput as any,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={4} lg={3} xl={2}>
                                            <Input
                                              name="userAddress.requesterName"
                                              variant="outlined"
                                              label={t('requesterName')}
                                              className={classes.input}
                                              disabled={isLoading}
                                              autoComplete="nope"
                                              maxLength={150}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                  </>
                                )}
                                {(!state.examGroup?.requiresSampleWithdrawal || documentTypeOfSampleWithdrawal === 'cpf') && (
                                  <Grid item xs={12} sm={4} lg={3} xl={2}>
                                    <Input
                                      name="userAddress.requesterCpf"
                                      variant="outlined"
                                      label={t('holderCpf')}
                                      className={classes.input}
                                      disabled={isLoading}
                                      autoComplete="off"
                                      InputProps={{
                                        inputComponent: DocumentInput as any,
                                      }}
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={12} sm={5} lg={5}>
                                  <Input
                                    name="userAddress.recipientName"
                                    variant="outlined"
                                    label={
                                      state.examGroup?.sampleWithdrawalRecipientLabel
                                        ? (state.procerdureLogisticType
                                          !== ProcedureLogisticType.LOGISTIC_COMPANY
                                          ? t('holderName')
                                          : t('holderSectorDepartmentSampleName'))
                                        : ''
                                    }
                                    className={classes.input}
                                    disabled={isLoading}
                                    autoComplete="off"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3}>
                                  <Select
                                    name="userAddress.recipientType"
                                    label={t('recipientType')}
                                    variant="outlined"
                                    preSelected={state.recipientTypes.length === 1
                                      ? state.recipientTypes[0] : null}
                                    fullWidth
                                  >
                                    {state.recipientTypes?.map((rt, i) => (
                                      <MenuItem
                                        key={i}
                                        value={rt}
                                      >
                                        {t(rt.toLowerCase())}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                                {state.examGroup?.requiresSampleWithdrawal
                                  && state.procerdureLogisticType
                                  === ProcedureLogisticType.LOGISTIC_COMPANY && (
                                    <Grid item xs={12} sm={4} lg={4}>
                                      <Select
                                        name="userAddress.pickUpTime"
                                        label={t('pickUpTime')}
                                        variant="outlined"
                                        fullWidth
                                      >
                                        <MenuItem value="MORNING">{t('morning')}</MenuItem>
                                        <MenuItem value="AFTERNOON">{t('afternoon')}</MenuItem>
                                      </Select>
                                    </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {(state.examGroup?.requiredAddress) && (
                      <Grid item>
                        <Grid container spacing={2} direction="column" className={classes.nowrap}>
                          <Grid item>
                            <Grid container spacing={2} direction="row">
                              <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant="h6">
                                  <strong>Endereço</strong>
                                </Typography>
                              </Grid>
                              {
                                !state.examGroup?.requiresSampleWithdrawal && (
                                  <Grid item xs={12} sm={3} lg={3}>
                                    <Select
                                      name="userAddress.recipientType"
                                      label={t('recipientType')}
                                      variant="outlined"
                                      preSelected={state.recipientTypes.length === 1
                                        ? state.recipientTypes[0] : null}
                                      fullWidth
                                    >
                                      {state.recipientTypes?.map((rt, i) => (
                                        <MenuItem
                                          key={i}
                                          value={rt}
                                        >
                                          {t(rt.toLowerCase())}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                )
                              }
                              <Grid item xs={12} sm={3} lg={3}>
                                <Input
                                  name="userAddress.cep"
                                  variant="outlined"
                                  label={t('cep')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="off"
                                  maxLength={9}
                                  onValueChange={handleCEPChange}
                                />
                              </Grid>
                              <Grid item xs={12} sm={9} lg={9}>
                                <Input
                                  name="userAddress.placeDescription"
                                  variant="outlined"
                                  label={t('placeDescription')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  maxLength={150}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3} lg={3}>
                                <Input
                                  name="userAddress.number"
                                  variant="outlined"
                                  label={t('number')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  maxLength={20}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={4}>
                                <Input
                                  name="userAddress.complement"
                                  variant="outlined"
                                  label={t('complement')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  maxLength={50}
                                  optional
                                />
                              </Grid>
                              <Grid item xs={12} sm={5} lg={5}>
                                <Input
                                  name="userAddress.neighborhood"
                                  variant="outlined"
                                  label={t('neighborhood')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  maxLength={50}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={4}>
                                <Input
                                  name="userAddress.city"
                                  variant="outlined"
                                  label={t('city')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  maxLength={50}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} lg={4}>
                                <Input
                                  name="userAddress.state"
                                  variant="outlined"
                                  label={t('state')}
                                  className={classes.input}
                                  disabled={isLoading}
                                  autoComplete="nope"
                                  maxLength={50}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item>
                      <Grid container spacing={2} direction="row" alignItems="flex-end">
                        <Grid item xs={12} sm>
                          <Grid container direction="column" spacing={3} xs={12} sm>
                            {buildAidFields() }
                            {buildPatientAcceptFields()}
                            {buildBottomProcedureFields()}
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Button type="submit" variant="contained" color="primary">
                            Solicitar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </PaperContent>
      </Paper>
      {(state.showUnselectedExamsDialog) && (
        <UnselectedExamsDialog
          onCancel={closeUnselectedExamsDialog}
          onConfirm={confirmUnselectedExamsDialog}
        />
      )}
    </Box>
  );
}
