import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Slide,
  Fade,
} from '@material-ui/core';
import { Form } from '@unform/web';
import {
  t,
  extractValidationErrors,
  signupDoctorSearchForm,
  ValidationError,
  GetDoctorByCrm,
  GetDoctorByCrmParams,
  Program,
  getProgramBySlug,
} from '@psp/common';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useLoading } from '../../contexts/loading.context';
import SigninNavbar from '../SigninNavbar';
import Button from '../Button';
import CrmInput from '../CrmInput';
import Input from '../Input';
import StateSelect from '../StateSelect';
import { useStyles } from './styles';
import SigninFooter from '../SigninFooter';
import ProgramStyles from '../ProgramStyles';
import { useQuery } from '../../hooks/use-query';

const slideTimeout = {
  enter: 500,
  exit: 400,
};

const fadeTimeout = {
  enter: 1000,
  exit: 200,
};

export type RecoverLoginProps = {
  getDoctorByCrm: GetDoctorByCrm;
};

export default function RecoverLogin({
  getDoctorByCrm,
}: RecoverLoginProps): JSX.Element {
  const classes = useStyles();
  const forgotEmailFormRef = useRef({} as any);
  const [step, setStep] = useState(0);
  const [doctorExists, setDoctorExists] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [userApproved, setUserApproved] = useState(false);
  const [encryptedEmail, setEncryptedEmail] = useState('');
  const { program = '' } = useQuery();
  const [loadedProgram, setLoadedProgram] = useState<Program | Record<string, never> | undefined>(
    undefined,
  );
  const { showLoading, hideLoading, showBackdrop, hideBackdrop } = useLoading();

  const encryptEmail = (email: string) => {
    const [localPart, domain] = email.split('@');
    const visiblePart = localPart.slice(0, 3);
    const hiddenPart = '*'.repeat(localPart.length - 3);
    return `${visiblePart}${hiddenPart}@${domain}`;
  };

  const handleBackClick = useCallback((): void => {
    setStep(step - 1);
  }, [step]);

  const handleSubmit = useCallback(
    (data) => {
      showLoading();
      async function runAsync() {
        try {
          forgotEmailFormRef.current.setErrors({});
          setDoctorExists(false);
          const fixData = {
            ...data,
            crm: data.crm.replace(/^0*(.*)$/, '$1'),
          };
          forgotEmailFormRef.current.setData(fixData);
          const req = (await signupDoctorSearchForm.validate(fixData, {
            abortEarly: false,
          })) as GetDoctorByCrmParams;
          const res = await getDoctorByCrm.execute({
            ...req,
            programId: loadedProgram?.id,
          });
          setStep(1);

          if (res.userExists) {
            setUserExists(res.userExists);
            setDoctorExists(true);
          }
          if (res.email) {
            const email = encryptEmail(res.email);
            setEncryptedEmail(email);
          }
        } catch (err) {
          if (err.message === 'invalidCRM') {
            toast.info(loadedProgram?.customMessageInvalidCRM ?? t('validation.invalidCRM'), {
              onOpen: showBackdrop,
              onClose: hideBackdrop,
            });
          } else {
            let errors = {};
            if (err instanceof ValidationError) {
              errors = extractValidationErrors(err);
              forgotEmailFormRef.current.setErrors(errors);
            } else {
              toast.info(t('err.failedToSearchDoctor'), {
                onOpen: showBackdrop,
                onClose: hideBackdrop,
              });
            }
          }
        } finally {
          hideLoading();
        }
      }
      runAsync();
    },
    [setStep, loadedProgram],
  );

  useEffect(() => {
    if (program) {
      (async () => {
        try {
          const res = await getProgramBySlug.execute({
            slug: program,
          });
          setLoadedProgram(res);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      setLoadedProgram({});
    }
  }, [program]);

  if (!loadedProgram) {
    return <></>;
  }

  return (
    <Grid container direction="column" className={clsx(classes.container, 'recover-login-container')}>
      <ProgramStyles program={loadedProgram} />
      <Grid item className={classes.navbarContainer}>
        <SigninNavbar />
      </Grid>
      <Grid item xs className={classes.main}>
        <Container className={classes.mainContainer}>
          <Grid container spacing={4} direction="column">
            <Grid item xs>
              <div className={classes.titleContainer}>
                <Typography variant="h1" color="primary" className={classes.title}>
                  {t('pages.recoverLogin.title')}
                </Typography>
                <Typography variant="h2" className={classes.subtitle}>
                  {t('pages.recoverLogin.subtitle')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm>
                  <Slide in={step === 0} timeout={slideTimeout} direction="up">
                    <Grid container>
                      <Fade in={step === 0} timeout={fadeTimeout}>
                        <Grid item xs className={classes.box}>
                          <Form ref={forgotEmailFormRef} onSubmit={handleSubmit}>
                            <Typography variant="body1" color="primary">
                              {t('pages.recoverLogin.firstStep.title')}
                            </Typography>
                            <br />
                            <Grid container spacing={2}>
                              <Grid item xs={8} sm>
                                <Input
                                  name="crm"
                                  variant="outlined"
                                  label={t('crm')}
                                  fullWidth
                                  helperText={t('helperText.numbersOnly')}
                                  className={classes.crmInput}
                                  InputProps={{
                                    inputComponent: CrmInput as any,
                                    className: classes.input,
                                  }}
                                  disabled={step > 0}
                                />
                              </Grid>
                              <Grid item xs>
                                <StateSelect
                                  name="uf"
                                  variant="outlined"
                                  label={t('uf')}
                                  fullWidth
                                  displayEmpty
                                  disabled={step > 0}
                                  inputProps={{
                                    className: classes.input,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm="auto">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  type="submit"
                                  className={classes.searchButton}
                                  disabled={step > 0}
                                >
                                  {t('search')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Form>
                        </Grid>
                      </Fade>
                    </Grid>
                  </Slide>
                </Grid>
              </Grid>
              <Grid item xs={12} sm>
                <Slide in={step >= 1} timeout={slideTimeout} direction="up">
                  <Grid container>
                    <Fade in={step >= 1} timeout={fadeTimeout}>
                      <Grid item xs className={classes.box}>
                        {doctorExists && userExists && (
                          <Typography variant="body1" align="center" color="primary">
                            Olá, encontramos seu cadastro.
                            <br />
                            Seu login:
                            <br />
                            {encryptedEmail}
                            <br />
                            <Grid container item xs={12} sm="auto" spacing={2} justify="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  className={classes.goBackButton}
                                  style={{ margin: '20px 0' }}
                                  onClick={() => {
                                    window.location.href = `/login?program=${program || ''}`;
                                  }}
                                >
                                  {t('goBackToLogin')}
                                </Button>
                              </Grid>
                            </Grid>
                            Caso ainda tenha dúvidas entre em contato com:
                            <br />
                            <a href="mailto:abracaravida@funcionalcorp.com.br">abracaravida@funcionalcorp.com.br</a>
                          </Typography>
                        )}
                        {!doctorExists && !userExists && (
                          <Typography variant="body1" align="center" color="primary">
                            {t('err.failedToSearchDoctor')}
                            <br />
                            Confira os dados digitados e tente novamente ou cadastre-se
                            <br />
                            <Grid container item xs={12} sm="auto" spacing={2} justify="center" style={{ margin: '20px 0' }}>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={handleBackClick}
                                >
                                  {t('goBack')}
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={() => {
                                    window.location.href = `/signup?program=${program || ''}`;
                                  }}
                                >
                                  {t('register')}
                                </Button>
                              </Grid>
                            </Grid>
                            <br />
                            Caso ainda tenha dúvidas entre em contato com:
                            <br />
                            <a href="mailto:abracaravida@funcionalcorp.com.br">abracaravida@funcionalcorp.com.br</a>
                          </Typography>
                        )}
                      </Grid>
                    </Fade>
                  </Grid>
                </Slide>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item className={classes.footerContainer}>
        <SigninFooter program={loadedProgram} />
      </Grid>
    </Grid>
  );
}
