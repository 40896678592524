import React, { memo, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import SignupComposer from '../composers/Signup';
import PrivateRoute from '../components/PrivateRoute';
import { useAuth } from '../contexts/auth.context';
import { useRoutes } from '../contexts/routes.context';
import { MainLayout } from '../layouts';
import {
  DOCTORS_ROUTE,
  DOCTOR_DETAIL_ROUTE,
  EXAM_REQUEST_ROUTE,
  INVENTORY_DISTRIBUTION_ROUTE,
  INVENTORY_ROUTE,
  MEDICATION_RELEASE_ROUTE,
  PATIENT_CONFIRMATION_SHORT_ROUTE,
  PROCEDURES_ROUTE,
  PROCEDURE_DETAIL_ROUTE,
  USERS_ROUTE,
  USER_EDIT_ROUTE,
  USER_DETAILS_ROUTE,
  USER_REGISTER_ROUTE,
  PCDT_ROUTE,
} from '../constants';
import DoctorDetailsPage from '../pages/doctors/details';
import DoctorListPage from '../pages/doctors/list';
import ExamRequestPage from '../pages/exams/request';
import HomePage from '../pages/home';
import RecoverLoginPage from '../pages/recover-login';
import InventoryDistributionPage from '../pages/inventory/distribution';
import InventoryPage from '../pages/inventory/list';
import MedicationReleasePage from '../pages/medications/release';
import PatientConfirmationPage from '../pages/patients/confirmation';
import ProcedureListPage from '../pages/procedures/list';
import UserListPage from '../pages/users/list';
import UserRegisterPage from '../pages/users/register';
import ProcedureDetailsPage from '../pages/procedures/details';
import SigninComposer from '../composers/Signin';
import PendingUsagePoliciesComposer from '../composers/PendingUsagePoliciesComposer';
import ContentsPage from '../pages/contents';
import { News1 } from '../components/Contents/News/news';
import PcdtPage from '../pages/pcdt';

function Routes(): JSX.Element {
  const { isAdmin, isAuthenticated, isDoctor, isLoading, hasPendingPolicy } = useAuth();
  const { routes } = useRoutes();

  const listItems = useMemo(
    () => routes.map(({ route, component, exact }) => (
      <PrivateRoute path={route} component={component} exact={exact} key={route} />
    )),
    [routes],
  );

  if (isLoading) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={PATIENT_CONFIRMATION_SHORT_ROUTE} component={PatientConfirmationPage} exact />
        {!isAuthenticated ? (
          [
            <Route key="route_signup" path="/signup" component={SignupComposer} />,
            <Route key="route_login" path="/login" component={SigninComposer} />,
            <Route key="route_home" path="/" exact component={HomePage} />,
            <Route key="route_recover_login" path="/recover-login" component={RecoverLoginPage} />,
            <Route key="route_contents" path="/contents" exact component={ContentsPage} />,
            <Route key="route_contents_news" path="/contents/o-que-e-diabetes" exact component={News1} />,
            <Route key="route_pcdt" path="/pcdt" exact component={PcdtPage} />,
            <PrivateRoute key="private_route_redirect" path="/*" component={React.Fragment} />,
          ]
        ) : !hasPendingPolicy ? (
          <PrivateRoute path="/" component={React.Fragment}>
            <MainLayout>
              <Switch>
                {listItems}
                <Route path="/">{!!routes.length && <Redirect to={routes[0].route} />}</Route>
              </Switch>
            </MainLayout>
          </PrivateRoute>
        ) : (
          <Route path="/" component={PendingUsagePoliciesComposer} />
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default memo(Routes);
