import React from 'react';

import { t } from '@psp/common';
import PcdtComposer from '../../composers/PCDT';
import Breadcrumbs from '../../components/Breadcrumbs';
import { PCDT_ROUTE } from '../../constants';

export default function PcdtPage(): JSX.Element {
  return (
    <>
      <Breadcrumbs title={t(`navigation.${PCDT_ROUTE}`)} />
      <PcdtComposer />
    </>
  );
}
