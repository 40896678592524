import React from 'react';

import {
  getProgramFiles,
  deleteProgramFile,
  downloadProgramFile,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
} from '@psp/common';
import PCDT, { PcdtDisplayProps } from '../../components/PCDT';

const dispatchers: PcdtDisplayProps = {
  getProgramFiles,
  deleteProgramFile,
  downloadProgramFile,
  getProgramProducts,
  getProductPresentations,
  getProductPatientProcedures,
  getProgramFollowups,
  getFollowupExamGroups,
  getExamGroupExams,
  getProgramSupplyGroups,
  getSupplyGroupSupplies,
  createProgramFile,
};

export default function PcdtComposer(): JSX.Element {
  return <PCDT {...dispatchers} />;
}
