// AUTH
export const SIGNIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const LOGOUT_ROUTE = '/logout';
export const PASSWORD_CHANGE_ROUTE = '/change-password';

// DASHBOARD
export const DASHBOARD_ROUTE = '/dashboard';

// DOCTORS
export const DOCTORS_ROUTE = '/doctors';
export const DOCTOR_DETAIL_ROUTE = '/doctors/:id';

// EXAMS
export const EXAM_REQUEST_ROUTE = '/exams/request';

// INVENTORY
export const INVENTORY_ROUTE = '/inventory';
export const INVENTORY_DISTRIBUTION_ROUTE = '/inventory/distribution';

// MEDICATIONS
export const MEDICATION_RELEASE_ROUTE = '/medications/release';
export const MEDICINE_LEAFLETS_ROUTE = '/medicine/leaflets';

// PATIENTS
export const PATIENT_CONFIRMATION_SHORT_ROUTE = '/p/:code';
export const PATIENTS_ROUTE = '/patients';
export const PATIENT_DETAIL_ROUTE = '/patients/:id';
export const PATIENT_INVITATION_ROUTE = '/patients/invitation';

// PATIENT PROCEDURE
export const PATIENT_PROCEDURE_REQUEST_ROUTE = '/patient-procedures/request';

// PROCEDURES
export const PROCEDURES_ROUTE = '/procedures';
export const PROCEDURES_FIRST_MEDICINE_ROUTE = '/procedures/medications';
export const PROCEDURES_EXAMS_ROUTE = '/procedures/exams';
export const PROCEDURES_PATIENT_PROCEDURES_ROUTE = '/procedures/patient-procedures';
export const PROCEDURES_SALESFORCE_VISITS_ROUTE = '/procedures/visits';
export const PROCEDURES_FIRST_MEDICINE_DETAIL_ROUTE = '/procedures/medications/:id';
export const PROCEDURES_EXAMS_DETAIL_ROUTE = '/procedures/exams/:id';
export const PROCEDURES_PATIENT_PROCEDURES_DETAIL_ROUTE = '/procedures/patient-procedures/:id';
export const PROCEDURES_SALESFORCE_VISITS_DETAIL_ROUTE = '/procedures/visits/:id';
export const PROCEDURE_DETAIL_ROUTE = '/procedures/:id';

// USERS
export const USERS_ROUTE = '/users';
export const USER_REGISTER_ROUTE = '/users/register';
export const USER_EDIT_ROUTE = '/users/:id/edit';
export const USER_DETAILS_ROUTE = '/user/edit';
export const USER_PERMISSION_ADD_ROUTE = '/users/:id/permissions/add';
export const USER_USER_GROUPS_ROUTE = '/users/:id/user-groups';
export const USER_USER_GROUPS_HISTORY_ROUTE = '/users/:id/user-groups/history';
export const USER_EMAIL_CHANGE_ROUTE = '/users/change-email';

// CLIENTS
export const CLIENTS_ROUTES = '/clients';
export const CLIENT_EDIT_ROUTE = '/clients/:id/edit';
export const CLIENT_REGISTER_ROUTE = '/clients/register';

// USER GROUPS
export const USER_GROUPS_ROUTE = '/user-groups';
export const USER_GROUP_PERMISSIONS_ROUTE = '/user-groups/:id/permissions';

// SUPPLY
export const SUPPLY_REQUEST_ROUTE = '/supply/request';
export const SUPPLY_REQUESTS_ROUTE = '/supply-requests';
export const SUPPLY_REQUESTS_DETAIL_ROUTE = '/supply-requests/:id';

// TRAVEL
export const TRAVEL_REQUEST_ROUTE = '/travel/request';
export const TRAVEL_REQUESTS_ROUTE = '/travel-requests';
export const TRAVEL_REQUESTS_DETAIL_ROUTE = '/travel-requests/:id';

// MEDICATION ADMINISTRATION
export const MEDICATION_ADMINISTRATION = '/medication-administration/:id';

// LIBRARY
export const LIBRARY_ROUTE = '/library';

// PRODUCTS
export const PRODUCTS_ROUTE = '/products';
export const PRODUCT_REGISTER_ROUTE = '/products/register';
export const PRODUCT_EDIT_ROUTE = '/products/:id/edit';

// PROGRAMS
export const PROGRAMS_ROUTE = '/programs';
export const PROGRAM_REGISTER_ROUTE = '/programs/register';
export const PROGRAM_EDIT_ROUTE = '/programs/:id/edit';

// FOLLOWUP
export const FOLLOWUPS_ROUTE = '/followups';
export const FOLLOWUP_REGISTER_ROUTE = '/followups/register';
export const FOLLOWUP_EDIT_ROUTE = '/followups/:id/edit';
export const FOLLOWUP_REMOVE_ROUTE = '/followups/:id/remove';

// PRESENTATIONS
export const PRESENTATIONS_ROUTE = '/presentations';
export const PRESENTATION_REGISTER_ROUTE = '/presentations/register';
export const PRESENTATION_EDIT_ROUTE = '/presentations/:id/edit';

// DISCOUNTS
export const DISCOUNTS_ROUTE = '/discounts';
export const PROGRAM_ID = 126;

// RECAPTCHA SITE KEY
export const SITE_KEY_CAPTCHA = process.env.SITE_KEY_CAPTCHA || '6LdSkAMqAAAAAHrD2MedB1Gvp9O2z_Vm4BGKgsGM';

// CONTENTS
export const CONTENTS_ROUTE = '/contents';

// PCDT
export const PCDT_ROUTE = '/pcdt';

// WSACESSO REST
export const FUNCIONAL_WS_ACESSO_REST_CLIENT = 'FUNCIONAL_WS_ACESSO';
export const FUNCIONAL_WS_ACESSO_REST_URI = 'https://qaswsacesso.funcionalmais.com/WsService.svc/rest';
export const FUNCIONAL_WS_ACESSO_REST_LOGIN = 'userBoehringer';
export const FUNCIONAL_WS_ACESSO_REST_PASSWORD = 'GYc3rUBMi4CRmOW5TfSWOlBRc8o';
export const FUNCIONAL_WS_ACESSO_REST_ORIGEM = '19';
